(function($) {
    objectFitImages();


$(window).on('resize load', function(){
    detect_touchDevice();
});
// Force external links to open in new tab / window
$(function() {
    var siteName = window.location.host.replace('www.', '');
    $('a[href^="htt"]:not([target]):not([href*="www.' + siteName + '"]):not([href*="learn."]):not([href*="//' + siteName + '"]):not([class*="fancybox"])').attr('target', '_blank');
});
})(jQuery);

function detect_touchDevice() {
var is_touch_device = 'ontouchstart' in document.documentElement;
if (is_touch_device) {
    jQuery('body').addClass('touch');
}
// Add class on touch device//

if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
    )
) {
    jQuery('body').addClass('touch');
} else {
    jQuery('body').removeClass('touch');
}
}