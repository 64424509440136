(function ($){
  if($('.card-grid').length > 0){
    //document click will close the dropdown
    $(document).on("click", function (e) {
        var container = $("header .content-wrap .filter-wrap .filter-item");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
        $(filter_block).removeClass("active");
        $(filter_content_wrap).slideUp();
        }
    });


    var filter_block = $('header .content-wrap .filter-wrap .filter-item');
    var filter_item = $('header .content-wrap .filter-wrap .filter-item p, header .content-wrap .filter-wrap .filter-item .arrow');
    var filter_content_wrap = $('header .content-wrap .filter-wrap .filter-item ul');
    var filter_content_item = $('header .content-wrap .filter-wrap .filter-item ul li input');
    filter_item.on('click',function(){
        if($(this).parent('.active').length > 0){
          $(filter_content_wrap).slideUp();
          $(filter_block).removeClass('active');
        }
        else{
          $(filter_content_wrap).slideUp();
          $(filter_block).removeClass('active');
          $(this).parent().toggleClass('active');
          $(this).parent().find(filter_content_wrap).slideToggle();
        }
    });


     $(document).ready(function(){
      var tot_emp_len = $('.card-grid .row .card-content .card-item').length;
      var tot_agency_len = $('header .content-wrap .filter-wrap .filter-item[data-group="second"] ul li').length;
      var tot_loc_len = $('header .content-wrap .filter-wrap .filter-item[data-group="third"] ul li').length;
      $('#agency_text').text(tot_agency_len);
      $('#states_text').text(tot_loc_len);
      $('#agency_text_right').text(tot_agency_len);
      $('#states_text_right').text(tot_loc_len);
        // init Isotope
        var $container = $('.grid').isotope({
          itemSelector: '.js-isotope-item',
          getSortData: {name: '.name'},
          sortBy: 'name',
          masonry: {
            // isFitWidth: true
            }
        });
        //  $container.on('arrangeComplete', disableEmptySortButtons);
        // store filter per group
        var filters = {};
        // var $filterDisplay = $('#output');
        // do stuff when checkbox change
        $(filter_content_item).on('click',function( event ) {
          $('header .content-wrap .filter-wrap .filter-item ul li input').removeAttr('disabled');
         $('header .content-wrap .filter-wrap .filter-item ul li input').removeClass('disabled');
         $('header .content-wrap .filter-wrap .filter-item ul li').removeClass('disabled');
          var checkbox = event.target;
          var $checkbox = $( checkbox );
          var group = $checkbox.parents('.filter-item').attr('data-group');
          // create array for filter group, if not there yet
          var filterGroup = filters[ group ];
          if ( !filterGroup ) {
            filterGroup = filters[ group ] = [];
          }
            // add filter
            if (  filterGroup.indexOf( checkbox.value ) == -1 ) {
              filterGroup.push( checkbox.value );
            }
          else {
            // remove filter
            var index =  filterGroup.indexOf( checkbox.value);
            if ( index != -1 ) {
              filterGroup.splice( index, 1 );
            }
          }
          var comboFilter = getComboFilter();
          $container.isotope({ filter: comboFilter, sortBy: 'name'});
          // $container.on('arrangeComplete', disableEmptySortButtons);
          // $filterDisplay.text( comboFilter ); 
          setTimeout(function(){
            disableEmptySortButtons();
          },500);
          updateFilterCount();
           
           var input_check = $('header .content-wrap .filter-wrap .filter-item ul li input:checked').length;
           if(input_check > 0){
            setTimeout(function(){
              disableEmptySortButtons();
            },500);
            updateFilterCount();

            setTimeout(function(){
              $('.card-grid .row .card-content .card-item:visible').each(function(){
                var loc_fil = $(this).attr('location');
                   $('.hero-banner').attr('class', 'hero-banner filter_active');
                   setTimeout(function(){
                    $('.hero-banner').addClass(loc_fil+' filter_active');
                   },5)
              });
             
             },500);
           }
           else{
            $('header .content-wrap .filter-wrap .filter-item ul li input').removeAttr('disabled');
            $('header .content-wrap .filter-wrap .filter-item ul li input').removeClass('disabled');
            setTimeout(function(){
              disableEmptySortButtons();
            },500);
            updateFilterCount();
             $('.hero-banner').attr('class', 'hero-banner');
            // $('header .content-wrap .clear span').trigger('click');
           }
          

          setTimeout(function(){
            let agency_len = $('header .content-wrap .filter-wrap .filter-item[data-group="second"] ul li:not(.disabled)').length;
            var tot_agency_len = $('header .content-wrap .filter-wrap .filter-item[data-group="second"] ul li').length;
            if(agency_len > 0){
              $('#agency_text').text(agency_len);
              $('#agency_text_right').text(agency_len);
              if(agency_len == 1){
                $('#agency_text').parent('p').addClass('single');
                $('#agency_text_right').parent('p').addClass('single');
              }
              else{
                $('#agency_text').parent('p').removeClass('single');
                $('#agency_text_right').parent('p').removeClass('single');
              }
            }
            else{
              $('#agency_text').text(tot_agency_len);
              $('#agency_text_right').text(tot_agency_len);
              $('#agency_text').parent('p').removeClass('single');
              $('#agency_text_right').parent('p').removeClass('single');
            }
            var loc_len = $('header .content-wrap .filter-wrap .filter-item[data-group="third"] ul li:not(.disabled)').length;
            var tot_loc_len = $('header .content-wrap .filter-wrap .filter-item[data-group="third"] ul li').length;
            if(loc_len > 0){
              $('#states_text').text(loc_len);
              $('#states_text_right').text(loc_len);

              if(loc_len == 1){
                $('#states_text').parent('p').addClass('single');
                $('#states_text_right').parent('p').addClass('single');
              }
              else{
                $('#states_text').parent('p').removeClass('single');
                $('#states_text_right').parent('p').removeClass('single');
              }
            }
            else{
              $('#states_text').text(tot_loc_len);
              $('#states_text_right').text(tot_loc_len);
              $('#states_text').parent('p').removeClass('single');
              $('#states_text_right').parent('p').removeClass('single');
            }

            var emp_len = $('.card-grid .row .card-content .card-item:visible').length;
            if(emp_len > 0){
              if(emp_len == 1){
                $('#employee_text').parent('p').addClass('single');
                $('#employee_text_right').parent('p').addClass('single');
              }
              else{
                $('#employee_text').parent('p').removeClass('single');
                $('#employee_text_right').parent('p').removeClass('single');
              }
            }
            else{
              $('#employee_text').parent('p').removeClass('single');
              $('#employee_text_right').parent('p').removeClass('single');
            }
          },800);

           setTimeout(function(){
            if($('.card-grid .row .card-content .card-item:visible').length == 0){
              $('.card-grid').addClass('no-result');
            }
            else{
              $('.card-grid').removeClass('no-result');
            }
           },700);

    });
    
    function getComboFilter() {
      var combo = [];
      for ( var prop in filters ) {
        var group = filters[ prop ];
        if ( !group.length ) {
          // no filters in group, carry on
          continue;
        }
        // add first group
        if ( !combo.length ) {
          combo = group.slice(0);
          continue;
        }
        // add additional groups
        var nextCombo = [];
        // split group into combo: [ A, B ] & [ 1, 2 ] => [ A1, A2, B1, B2 ]
        for ( var i=0; i < combo.length; i++ ) {
          for ( var j=0; j < group.length; j++ ) {
            var item = combo[i] + group[j];
            nextCombo.push( item );
          }
        }
        combo = nextCombo;
      }
      var comboFilter = combo.join(', ');
      return comboFilter;
     
    }
    var iso = $container.data('isotope');
    var $filterCount = $('#employee_text');  
    var $filterCountright = $('#employee_text_right');  
    function updateFilterCount() {
      $filterCount.text(iso.filteredItems.length);   
      $filterCountright.text(iso.filteredItems.length);   
    }
    updateFilterCount();
    var disableEmptySortButtons = function() {
      $('header .content-wrap .filter-wrap .filter-item[data-group="second"] ul li input, header .content-wrap .filter-wrap .filter-item[data-group="third"] ul li input').each(function(i, btn) {
        var $btn = $(btn),
            thisCategory = $btn.attr('value');
        if (thisCategory !== 'all' && $('.grid').find(thisCategory+':visible').length === 0) {
          $btn.addClass('disabled').attr('disabled', 'disabled');
          $btn.parent().addClass('disabled');
          // console.log('Disable Sort button: ' + thisCategory);
        }
      });
    };
     disableEmptySortButtons();
    $('header .content-wrap .clear span').on('click',function(){
      $('header .content-wrap .filter-wrap .filter-item ul li input:checked').trigger('click');
      $('.card-grid').removeClass('no-result');
      $container.isotope({
        filter: '*'
      });
      $('header .content-wrap .filter-wrap .filter-item ul li input').removeAttr('disabled');
      $('header .content-wrap .filter-wrap .filter-item ul li input').removeClass('disabled');
      $('header .content-wrap .filter-wrap .filter-item ul li').removeClass('disabled');
      updateFilterCount();
      $('#agency_text').text(tot_agency_len);
      $('#states_text').text(tot_loc_len);
      $('#agency_text_right').text(tot_agency_len);
      $('#states_text_right').text(tot_loc_len);
    });
  });
  
  
  
  $('.hero-banner .text-block .btn-wrap .collapse_btn').on('click',function(){
    var window_width = $(window).width();
    if(window_width < 992){
      $('.hero-banner .map-wrap').slideToggle();
      $('.hero-banner .content-wrapper').toggleClass('active');
    }
    else{
      $('.hero-banner .text-block .text-wrap').slideToggle();
      $('.hero-banner .map-wrap').slideToggle();
      $('.hero-banner .content-wrapper').toggleClass('active');
    }
  });

  $('.card-grid .row .card-content .card-item').mouseover(function() {
    var item_active = $(this);
    var item_loc = item_active.attr('location');
    $('.hero-banner').attr('id',item_loc);
  });
  $('.card-grid .row .card-content .card-item').mouseleave(function() {
    $('.hero-banner').removeAttr('id');
  });
   

  $(window).on('resize',function (){
    phone_filter();
   });
  phone_filter();
   function phone_filter(){
    var window_w = $(window).width();
    if(window_w <= 767){
      $('header .content-wrap .mobile-filter-wrap').unbind().click(function(){
        if($(this).parent().find('.filter-wrap.active').length > 0){
          $('header .content-wrap .mobile-filter-wrap').removeClass('active');
          // $('header .content-wrap .filter-wrap .filter-item ul').slideUp();
          $('header .content-wrap .filter-wrap').removeClass('active');
          $('header .content-wrap .filter-wrap').slideUp();
        }
        else{
          
          $('header .content-wrap .mobile-filter-wrap').addClass('active');
          $('header .content-wrap .filter-wrap').addClass('active');
          // $('header .content-wrap .filter-wrap .filter-item ul').slideUp();
          // $(this).parent().addClass('active');
           $('header .content-wrap .filter-wrap').slideDown();
        }
      });
     }else{
      $('header .content-wrap .mobile-filter-wrap').removeClass('active');
      // $('header .content-wrap .filter-wrap').slideDown();
      $('header .content-wrap .filter-wrap').removeAttr('style');
      $('header .content-wrap .filter-wrap').removeClass('active');
      $('header .content-wrap .filter-wrap .filter-item').removeAttr('style');
      $('header .content-wrap .filter-wrap .filter-item ul').slideUp();
     }

   }
  }
  
  
})(jQuery);